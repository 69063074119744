<script setup lang="ts">
const { session, score } = useAuth()
</script>

<template>
  <LayoutCard v-if="session && session.user" :gutter="false">
    <div
      data-testid="userscore"
      class="relative min-h-24 overflow-hidden rounded-t-xl bg-sky-950"
    >
      <figure class="absolute inset-x-0 bottom-0 -mb-px">
        <svg
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1920 100.1"
        >
          <path
            fill="currentColor"
            class="fill-white"
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </svg>
      </figure>
    </div>
    <div class="relative z-10 -mt-12 flex flex-col items-center justify-center">
      <Avatar
        :name="session.user.first_name ?? undefined"
        :image="session.user.avatar ?? undefined"
        size="big"
      />
      <TypographyHeading :level="3" class="heading mb-2 mt-1">{{
        session.user.first_name
      }}</TypographyHeading>
      <PointBadge />
    </div>
    <div class="flex flex-col gap-4 p-4">
      <div class="rounded-lg bg-slate-50 p-4">
        <p class="text-sm text-slate-600">
          {{ $t("resilience_score.long_text") }}
        </p>
      </div>
      <div v-if="score" class="grid grid-cols-1 gap-4 sm:grid-cols-2">
        <ScoreCard
          :title="$t('resilience_score.quizzing.title')"
          :text="$t('resilience_score.quizzing.text')"
          :value="score.quizzes"
        />
        <ScoreCard
          :title="$t('resilience_score.videos.title')"
          :text="$t('resilience_score.videos.text')"
          :value="score.videos"
        />
        <ScoreCard
          :title="$t('resilience_score.simulations.title')"
          :text="$t('resilience_score.simulations.text')"
          :value="score.simulations"
        />
        <ScoreCard
          :title="$t('resilience_score.skills.title')"
          :text="$t('resilience_score.skills.text')"
          :value="score.skills"
        />
      </div>
    </div>
  </LayoutCard>
</template>
