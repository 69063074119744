<script setup lang="ts">
import type { AsyncDataRequestStatus } from "nuxt/app"

const props = defineProps<{
  value?: number
  status: AsyncDataRequestStatus
}>()

const formattedValue = computed(() => (props.value === 0 ? 2 : props.value))

const progressClass = computed(() => {
  if (props.value == undefined) {
    return
  }
  if (props.value < 25) return "bg-red-500"
  if (props.value < 50) return "bg-orange-500"
  if (props.value < 75) return "bg-yellow-400"
  return "bg-teal-400"
})
</script>

<template>
  <div class="relative mb-4">
    <div class="flex h-2.5 w-full overflow-hidden rounded-full bg-slate-200">
      <div
        class="flex w-full flex-col justify-center overflow-hidden whitespace-nowrap bg-gradient-to-r from-red-500 via-yellow-400 to-teal-400 text-center text-xs text-white"
        role="progressbar"
        :aria-valuenow="value"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <div
      v-if="status == 'success' && value != undefined"
      :style="{ left: `calc(${formattedValue}% - 0.5rem)` }"
      :class="[
        progressClass,
        'absolute top-1/2 h-5 w-2 -translate-y-1/2 transform rounded-full border-2 border-white transition-all duration-300 ease-in-out',
      ]"
    ></div>
  </div>
</template>
